<template>
  <div class="home">
    <md-progress-bar v-if="query" md-mode="query"></md-progress-bar>
    <span class="warnings" v-if="!Object.values(show).reduce((x, y) => x && y)">Warning: Not all columns are selected. Change this in settings.<br /></span>
    <span class="warnings" v-if="onlyShowEmpty">Warning: Filter set, will be lost when data is updated.<br /></span>
    <span class="warnings" v-if="view">Warning: Only showing Objects belonging to {{view}}.<br /></span>
    <md-tabs md-alignment="fixed">
      <md-tab id="data" md-label="Data">

        <div class="navigation md-layout" v-if="filteredData.length>elemProPage">
          <md-button @click="currentPage > 1?currentPage = currentPage - 1:currentPage"><md-icon class="md-layout-item">arrow_left</md-icon></md-button>
          <span class="md-layout-item" style="text-align:center">Page {{currentPage}} of {{Math.ceil(filteredData.length/elemProPage)}}</span>
          <md-button @click="currentPage < Math.ceil(filteredData.length/elemProPage)?currentPage = currentPage + 1:currentPage"><md-icon class="md-layout-item">arrow_right</md-icon></md-button>
        </div>
        <md-table md-height="100%" class="myTable" md-card>
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              <h1 class="md-title">Text Data</h1>
            </div>
            <span v-if="uploading">Saving changes...</span>
              <md-button class="md-primary" @click="loadData">Update Data</md-button>
          </md-table-toolbar>

          <md-table-row>
            <md-table-head md-numeric v-if="show.textID">Text ID</md-table-head>
            <md-table-head v-if="show.Page">Page</md-table-head>
            <md-table-head v-if="show.Description">Description</md-table-head>
            <md-table-head v-if="show.EN">English</md-table-head>
            <md-table-head v-if="show.NL">Dutch</md-table-head>
            <md-table-head v-if="show.DE">German</md-table-head>
            <md-table-head v-if="show.FR">French</md-table-head>
            <md-table-head v-if="show.AR">Arabic</md-table-head>
            <md-table-head v-if="show.ES">Spanish</md-table-head>
            <md-table-head v-if="show.SE">Swedish</md-table-head>
            <md-table-head v-if="show.RU">Russian</md-table-head>
            <md-table-head v-if="show.UK">Ukrainian</md-table-head>
          </md-table-row>

          <md-table-row v-for="item in filterArrayForPage" v-bind:key="item.textId">
            <md-table-cell md-numeric>
              {{ item.textId }}
            </md-table-cell>
            <md-table-cell v-if="show.Page">
              {{item.view}}
            </md-table-cell>
            <md-table-cell v-if="show.Description">
<!--              <md-field md-inline>-->
<!--                <label>Your description</label>-->
<!--                <md-textarea v-model="item.Description" @change="change(item.textId, 'Description', item.Description)" md-autogrow></md-textarea>-->
<!--              </md-field>-->
              {{ item.Description }}
            </md-table-cell>
            <md-table-cell v-if="show.EN">
              <md-field md-inline>
                <label>Your english text</label>
                <md-textarea v-model="item.EN" @change="change(item.textId, 'EN', item.EN)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.NL">
              <md-field md-inline>
                <label>Your dutch text</label>
                <md-textarea v-model="item.NL" @change="change(item.textId, 'NL', item.NL)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.DE">
              <md-field md-inline>
                <label>Your german text</label>
                <md-textarea v-model="item.DE" @change="change(item.textId, 'DE', item.DE)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.FR">
              <md-field md-inline>
                <label>Your french text</label>
                <md-textarea v-model="item.FR" @change="change(item.textId, 'FR', item.FR)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.AR">
              <md-field md-inline>
                <label>Your arabic text</label>
                <md-textarea v-model="item.AR" @change="change(item.textId, 'AR', item.AR)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.ES">
              <md-field md-inline>
                <label>Your spanish text</label>
                <md-textarea v-model="item.ES" @change="change(item.textId, 'ES', item.ES)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.SE">
              <md-field md-inline>
                <label>Your swedish text</label>
                <md-textarea v-model="item.SE" @change="change(item.textId, 'SE', item.SE)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.RU">
              <md-field md-inline>
                <label>Your russian text</label>
                <md-textarea v-model="item.RU" @change="change(item.textId, 'RU', item.RU)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="show.UK">
              <md-field md-inline>
                <label>Your ukrainian text</label>
                <md-textarea v-model="item.UK" @change="change(item.textId, 'UK', item.UK)" md-autogrow></md-textarea>
              </md-field>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-tab>
      <md-tab id="settings" md-label="Settings">
        <md-card md-with-hover>
          <md-ripple>
            <md-card-header>
              <div class="md-title">Show columns</div>
            </md-card-header>

            <md-card-content >
              <md-switch v-model="show.textID" class="md-primary">ID</md-switch>
              <md-switch v-model="show.Page" class="md-primary">Page</md-switch>
              <md-switch v-model="show.Description" class="md-primary">Description</md-switch>
              <md-switch v-model="show.EN" class="md-primary">English</md-switch>
              <md-switch v-model="show.AR">Arabic</md-switch>
              <md-switch v-model="show.DE">German</md-switch>
              <md-switch v-model="show.ES">Spanish</md-switch>
              <md-switch v-model="show.SE">Swedish</md-switch>
              <md-switch v-model="show.FR">French</md-switch>
              <md-switch v-model="show.NL">Dutch</md-switch>
              <md-switch v-model="show.RU">Russian</md-switch>
              <md-switch v-model="show.UK">Ukrainian</md-switch>
            </md-card-content>
          </md-ripple>
        </md-card>

        <md-card md-with-hover>
          <md-ripple>
            <md-card-header>
              <div class="md-title">Show only empty</div>

            </md-card-header>

            <md-card-content>
              Language:
              <md-field>
                <md-select v-model="onlyShowEmpty" name="onlyShowEmpty" id="onlyShowEmpty" @md-selected="filterData">
                  <md-option value="">None</md-option>
                  <md-option value="AR">Arabic</md-option>
                  <md-option value="DE">German</md-option>
                  <md-option value="EN">English</md-option>
                  <md-option value="ES">Spanish</md-option>
                  <md-option value="SE">Swedish</md-option>
                  <md-option value="FR">French</md-option>
                  <md-option value="NL">Dutch</md-option>
                  <md-option value="RU">Russian</md-option>
                  <md-option value="UK">Ukrainian</md-option>
                </md-select>
            </md-field>
            </md-card-content>
          </md-ripple>
        </md-card>
        <md-card md-with-hover>
          <md-ripple>
            <md-card-header>
              <div class="md-title">Filter page</div>

            </md-card-header>

            <md-card-content>
              Page:
              <md-field>
                <md-select v-model="view" name="view" id="view" @md-selected="filterData">
                  <md-option value="">None</md-option>
                  <md-option v-for="view in viewOptions" :value="view" v-bind:key="view">{{ view }}</md-option>
                </md-select>
              </md-field>
            </md-card-content>
          </md-ripple>
        </md-card>
        <md-card md-with-hover>
          <md-ripple>
            <md-card-header>
              <div class="md-title">Elements per Page</div>

            </md-card-header>

            <md-card-content>
              Be careful, a number greater 50 might significantly decrease performance!
              <md-field>
                <md-input v-model="elemProPage" type="number">
                </md-input>
              </md-field>
            </md-card-content>
          </md-ripple>
        </md-card>
      </md-tab>
    </md-tabs>

  </div>
</template>

<script>
import {axiosGet, axiosPatch} from "@/utils/axiosWrapper";
export default {
  name: "KaiserDB",
  data () {
    return {
      query: false,
      uploading: false,

      onlyShowEmpty: "",
      view: "",
      viewOptions: [],
      elemProPage: 20,
      currentPage: 1,

      filteredData: {},
      myData: {},
      show: {
        textID: true,
        Page: true,
        Description: true,
        EN: true,
        NL: false,
        DE: true,
        FR: true,
        AR: false,
        ES: false,
        SE: false,
        RU: false,
        UK: false
      }
    }
  },
  components: {
  },
  methods: {
    loadData: async function (){
      this.query = true
      this.myData = (await axiosGet('/content')).data

      this.viewOptions = new Set(this.myData.map(item => item.view))
      this.onlyShowEmpty = ""

      this.filterData()

      this.query = false

    },
    filterData: function (){
      if(this.filteredData) {
        this.filteredData = this.myData
        if(this.onlyShowEmpty)
          this.filteredData = this.filteredData.filter((d) => !d[this.onlyShowEmpty])
        if(this.view)
          this.filteredData = this.filteredData.filter((d) => d.view == this.view)
      }
    },
    change: async function (id, language, content){
      let me = this
      this.uploading = true
      await axiosPatch('/content', {
        "textId": id,
        "language": language,
        "text": content
      })
          .then(function (res) {
            console.log("Updated text with status: " + res.status)
            me.filterData()
          })
          .catch(function (error) {
            alert('Your data could not be saved.\nReloading all data to prevent data loss.\n\n'+error)
            me.loadData()
          });
      this.uploading = false
    }
  },
  computed:{
    filterArrayForPage: function(){
      if(Object.prototype.hasOwnProperty.call(this.filteredData, length)) {
        let minIndex = (this.currentPage - 1) * this.elemProPage
        let maxIndex = (this.currentPage) * this.elemProPage
        return this.filteredData.slice(minIndex, maxIndex)
      }
      else {
        return []
      }
    },
  },
  mounted () {
    this.loadData()
  }
}
</script>
<style>
.headline{
  padding-left: 20px;
  margin-bottom: 0;
}
.subheader{
  margin-top: 0;
  margin-left: 40px;
}
.warnings{
  margin-left: 20px;
  padding-bottom: 30px;
  color: #e53935;
}
.info{
  padding-left: 20px;
  padding-bottom: 30px;
  color: #1976d2;
}
.navigation{

}
</style>
